/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'General Terms of Use';
  const description = 'Njano is a cloud-based flexible office management solution designed to manage workplaces';
  return <SEO title={title} description={description} location={location} />;
};

const PrivacyPolicy = () => (
  <Layout dark logo>
    <div className="md:max-w-5xl container w-full px-16 pt-20 mx-auto bg-white shadow-lg">
      <div className="md:px-6 w-full px-4 text-sm md:text-md leading-normal text-gray-800">
        <div className="font-content">
          <span className="text-primary md:text-sm space-y-8 text-base font-bold">
            <Link to="/" className="bg-secondary md:text-sm px-6 py-2 text-base font-bold text-white no-underline">
              &#8592; BACK TO HOME
            </Link>
            <h1 className="font-heading md:text-5xl pt-6 pb-2 text-3xl font-bold text-gray-900 break-normal">
              General Terms of Use
            </h1>
          </span>
        </div>
        <p className="py-2">
          These Terms of Use (hereinafter the &quot;ToU&quot;) govern the use of the Services as defined below and
          provided by Njano, allowing access to the Platform, the Features and the Services.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">Definitions</h2>
        <p className="py-2">
          The Parties agree and accept that the following terms used with a capital letter, in the singular and/or
          plural, shall have, within the framework of these General Terms of Use, the meaning defined below:
        </p>
        <ul className="list-inside list-disc space-y-1 my-2">
          <li>
            “Contract&quot;: refers to the present General Conditions of Use as well as the{' '}
            <Link to="/terms/privacy-policy" className="text-blue-600">
              Privacy Policy
            </Link>
          </li>
          <li>
            “Njano by Njanoo&quot; or &quot;Njano&quot;: refers to the offer proposed by Njanoo, entirely online,
            intended for Users who wish to register and access to the platform’s features
          </li>
          <li>“Space”: refers to any hub who has registered and invited their members to join Njano;</li>
          <li>
            “Member(s)&quot;:refers to any person who has registered to Njano and has access to the features defined by
            the hub he/she belongs to
          </li>
          <li>
            “External Member(s)&quot;: refers to any person who has registered to Njano and has only access to the
            hubs&#39; specific events, E-learning and benefit from the community feature
          </li>
          <li>“User(s)&quot; means any person who uses the Platform, whether or not they are a Member;</li>
          <li>“Platform&quot;: refers to the website enabling the use of the Services;</li>
          <li>“Services&quot;: refers to the Njano services and resources accessible online</li>
        </ul>
        <p className="py-2">
          These Terms of Use govern the relationship between Njanoo and the Users, i.e. the persons accessing the
          Platform&#39;s Services, applicable during the use of the Platform and until the User&#39;s account is
          deactivated. If the User does not agree with the terms of the ToU, it is strongly recommended not to use the
          Platform and the Services.
        </p>
        <p className="py-2">
          By browsing the Platform, Users acknowledge that they have read and accepted the entirety of these ToU and the{' '}
          <Link to="/terms/privacy-policy" className="text-blue-600">
            Privacy Policy
          </Link>{' '}
        </p>
        <p className="py-2">
          By creating an account, the User is invited to read and accept these ToU and the{' '}
          <Link to="/terms/privacy-policy" className="text-blue-600">
            Privacy Policy
          </Link>{' '}
          by ticking the box provided for this purpose.
        </p>
        <p className="py-2">
          Njanoo encourages Users to consult the &quot;Terms of Use&quot; and the &quot;Privacy Policy&quot; before
          using our Platform for the first time and regularly when they are updated. Njanoo may in fact be required to
          modify these ToU and the Privacy Policy. If changes are made, Njanoo will inform Users by email or via the
          Platform to enable them to examine the changes before they take effect. If the User continues to use the
          Platform after the publication or sending of a notice concerning the modifications made to these conditions,
          this will mean that the User accepts the updates. The ToU that will be enforceable will be those in force at
          the time of use of the Platform. If the User does not wish to continue using the Services under the terms of
          the new version of the Agreement, he/she may terminate his/her account by following the procedure displayed on
          the Platform and, if necessary, contact customer service at: contact@njano.org
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 1. Registration to the Services
        </h2>
        <h4>1.1 Conditions of access to the Platform</h4>
        <p className="py-2">
          Access to the Platform requires registration and the creation of an account. Before being able to register on
          the Platform, the User must have read and accepted these ToU and the{' '}
          <Link to="/terms/privacy-policy" className="text-blue-600">
            Privacy Policy
          </Link>{' '}
        </p>
        <p className="py-2">
          The User declares that he/she has the capacity to accept the present ToU, i.e. that he/she is over 16 years of
          age and is not subject to a legal protection measure for adults (legal protection, guardianship or
          curatorship).
        </p>
        <p className="py-2">
          Before accessing our Platform, the consent of minors under the age of 16 must be given by the holder of
          parental authority.
        </p>
        <p className="py-2">
          Our Platform makes no provision for the registration, collection or storage of information relating to any
          person aged 15 or under.
        </p>
        <h4>1.2 Account creation</h4>
        <p className="py-2">
          To create an account, the User is invited to manually fill in, on the Platform, the mandatory fields on the
          registration form, using complete and accurate information. In case the user is not invited by the manager to
          register his or her account, the User must submit certain personal information to Njanoo, such as last name,
          first name and email address. A description of the data processing is available in our{' '}
          <Link to="/terms/privacy-policy" className="text-blue-600">
            Privacy Policy
          </Link>{' '}
        </p>
        <p className="py-2">
          Following registration, the User will receive an email confirming registration and a request for confirmation
          of his/her email address.
        </p>
        <p className="py-2">
          When creating an account, the User undertakes to provide true, accurate and complete personal information and
          to update this information via his/her profile or by notifying Njanoo in order to guarantee its relevance and
          accuracy throughout his/her relationship with Njanoo.
        </p>
        <p className="py-2">
          The User undertakes to keep secret the password chosen when creating his/her account and not to communicate it
          to anyone. In the event of loss or disclosure of their password, the User undertakes to inform Njanoo without
          delay. The User is solely responsible for the use made of his/her account by a third party, as long as he/she
          has not expressly notified Njanoo of the loss, fraudulent use and/or disclosure of his/her password to a third
          party.
        </p>
        <p className="py-2">
          The User undertakes not to create or use, under his/her own identity or that of a third party, accounts other
          than the one initially created. User may not authorize others to use his or her account and may not assign or
          otherwise transfer his or her account to any other person or entity.
        </p>
        <p className="py-2">
          Information provided by User during registration may be corrected during the registration process by returning
          to previous screens and correcting incorrect information.
        </p>
        <p className="py-2">
          User agrees to comply with applicable laws when using the Services, and to use the Services only for lawful
          purposes. The content present on the Platform must only be used for private and personal use.
        </p>
        <p className="py-2">The User is strongly advised not to use a simplistic password.</p>
        <p className="py-2">
          The login and password will be strictly personal and confidential and the User must keep them and use them in
          such a way as to preserve their strict confidentiality.
        </p>
        <p className="py-2">
          The User shall be the only one authorized to access the Platform using his login and password. Any use of the
          Platform by means of his login and password is deemed to have been made by the User himself. In the event of
          use by a third party of its login and password, the User must immediately notify Njanoo by sending an email to
          the following address:{' '}
          <a href="&#109;&#97;&#105;&#108;&#x74;&#x6f;&#x3a;&#99;&#111;&#x6e;&#x74;&#x61;&#99;&#116;&#64;&#110;&#x6a;&#97;&#110;&#111;&#x2e;&#111;&#x72;&#103;">
            &#99;&#111;&#x6e;&#x74;&#x61;&#99;&#116;&#64;&#110;&#x6a;&#97;&#110;&#111;&#x2e;&#111;&#x72;&#103;
          </a>
          .
        </p>
        <p className="py-2">
          Users who have lost their password may go to the Njano site and follow the procedure by clicking on the
          &quot;Forgot password&quot; link.
        </p>
        <p className="py-2">
          The User is responsible for the use of the Platform and for all actions carried out within the Platform with
          his/her login and password, except if the use of his/her account was made after he/she was unregistered, or
          after Njanoo was notified of an abusive use of his/her account.
        </p>
        <h4>1.3. Types of access to the Platform</h4>
        <h5>1.3.1 Free User Account</h5>
        <p className="py-2">The User may have access to the Platform and part of the Services free of charge.</p>
        <p className="py-2">
          Following the creation of his account, the User can access the page of his profile where he fills in certain
          information that are mandatory and others that are optional.
        </p>
        <p className="py-2">
          Before using the Platform, the User is invited to carefully read the present General Conditions of Use. To use
          the Services, the User must have access to the Internet and a device compatible with the Platform.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 2. Duration and termination
        </h2>
        <p className="py-2">
          The Contract shall remain in force until the User&#39;s account is deleted, either by the User or by Njanoo.
          The account may be deleted by the User at any time by contacting Customer Services at the following address:{' '}
          <a href="&#109;&#97;&#x69;&#108;&#x74;&#111;&#58;&#99;&#x6f;&#110;&#116;&#x61;&#x63;&#116;&#x40;&#110;&#x6a;&#97;&#x6e;&#x6f;&#x2e;&#111;&#114;&#103;">
            &#99;&#x6f;&#110;&#116;&#x61;&#x63;&#116;&#x40;&#110;&#x6a;&#97;&#x6e;&#x6f;&#x2e;&#111;&#114;&#103;
          </a>
        </p>
        <p className="py-2">
          Njanoo may terminate the Contract or suspend access to the Services at any time, including in the event of
          actual or suspected unauthorized use of the Services and/or the Platform, non-compliance with the terms of the
          Contract or if Njanoo withdraws Services (in which case the User will be informed within a reasonable time).
        </p>
        <p className="py-2">
          In the event of termination of the Contract, either by the User or by Njanoo, or if Njanoo suspends access to
          the Services, Njanoo will not assume any responsibility towards the User and will not be liable for any sums
          that the User may have paid, within the limits authorized by current law.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 3. Information provided by the User
        </h2>
        <p className="py-2">
          Each person guarantees Njanoo that the information he or she provides regarding his or her identity and
          contact details within the framework of the Services is true, accurate, complete and up-to-date. The User is
          solely responsible for the truthfulness and accuracy of this information. The User undertakes to regularly
          update all information in order to maintain its accuracy.
        </p>
        <p className="py-2">
          Njanoo may not under any circumstances be held responsible for any errors, omissions or inaccuracies that may
          be found in the information provided by the User, nor for any prejudice that may result for other Users or for
          third parties.
        </p>
        <p className="py-2">
          The User is responsible for all activity that takes place on his account, and he agrees to maintain the
          security and secrecy of his login and password at all times. A User may have only one account.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 4. Intellectual Property
        </h2>
        <h4>4.1. Intellectual property relating to the Services, the Platform and the elements that make them up</h4>
        <p className="py-2">
          All of the technical, graphic, textual or other elements making up the Services and/or the Platform (texts,
          graphics, software, multimedia files, photographs, images, videos, sounds, plans, graphic charter,
          technology(ies), source codes, names, brands, logos, visuals, databases, etc.) as well as the Platform and the
          Services themselves are the exclusive property of Njanoo.
        </p>
        <p className="py-2">
          The User acknowledges that no license or intellectual property right of any kind whatsoever, in particular
          with regard to the Platform is transmitted or granted to him/her, other than the right to use the Services in
          accordance with these ToU for the duration of the Contract. No provision of these ToU shall be interpreted as
          expressly or implicitly granting a license under copyright, patent, trademark or any other intellectual
          property right of Njanoo
        </p>
        <p className="py-2">Consequently, the User is aware that he/she is not authorized to:</p>
        <ul className="list-inside list-disc space-y-1 my-2">
          <li>
            Reproduce, distribute, modify, publish, license, create derivative works, sell or use, for commercial or
            non-commercial purposes, the Modules present in the Services and/or the technical, graphic, textual or other
            elements constituting the Services and/or the Platform;
          </li>
          <li>
            Integrate all or part of the content of the Services and/or the Platform into a third-party site, for
            commercial or non-commercial purposes;
          </li>
          <li>
            Bypass, remove, modify, deactivate, destroy or defeat the protections of the content accessible through the
            Services from the Platform.
          </li>
          <li>
            Use robots, spiders, web harvesting tools or other automated methods, including crawling (including bots,
            spiders or scrapers), website search or retrieval application or any other means to extract, reuse or index
            all or part of the content of the Services and/or the Platform;
          </li>
          <li>
            Decompile, reverse engineer or disassemble any software, products or processes accessible through the
            Platform;
          </li>
          <li>
            Collect information about other Users in order to send them unsolicited messages and/or integrate them into
            a referral service or equivalent, whether free or paid;
          </li>
          <li>
            Copy the Modules available within the Services and/or the technical, graphic, textual or other elements
            constituting the services and/or the Platform onto media of any kind that allow the reconstitution of all or
            part of the original files.
          </li>
        </ul>
        <p className="py-2">
          Any unauthorized use of elements of the Services, Modules and/or the Platform engages the civil and/or
          criminal liability of its author and may result in legal proceedings against him/her.
        </p>
        <h4>4.2. Intellectual property relating to content published by Users on the Platform</h4>
        <p className="py-2">
          Users guarantee that they have the intellectual property rights and personality rights (and in particular, the
          right to an image) necessary for the publication of the content that they publish on the Platform and that
          will be shared with other Users of the Services.
        </p>
        <p className="py-2">
          They also guarantee that the content they publish on the Platform does not contain anything that is contrary
          to the rights of third parties and to the laws in force, and in particular to the provisions relating to
          defamation, insult, privacy, image rights, offences against public decency or counterfeiting.
        </p>
        <p className="py-2">
          Users guarantee Njanoo against any possible recourse by a third party concerning the publication of said
          content on the Platform.
        </p>
        <p className="py-2">4.3. Third Party Licenses</p>
        <p className="py-2">
          Njanoo uses open-source software in its products and services. The legal attributions and notices for used
          third-party software and components can be found at the following link:{' '}
          <Link to="/terms/open-source-licenses">https://njano.org/terms/open-source-licenses</Link> .
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 5. Discussion areas between Users
        </h2>
        <p className="py-2">
          Where applicable, Users have the option of accessing free discussion areas where they can consult discussion
          threads, public discussion chains and exchange views with other Users of the Platform.
        </p>
        <p className="py-2">
          The User undertakes not to broadcast private correspondence on the public discussion areas and must, for this
          purpose, use the private messaging service.
        </p>
        <p className="py-2">
          When posting a message, the User agrees to comply in particular with the provisions of the article
          &quot;Obligations of Users and Charter of Good Conduct&quot;.
        </p>
        <p className="py-2">
          Users may view other Users&#39; messages in the public discussion areas and report them, in accordance with
          the article &quot;Reporting - moderation a posterio&quot;.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 6. Reporting - a posteriori moderation
        </h2>
        <p className="py-2">
          Any User may report to Njanoo or to the hub any message or comment and more generally any content published on
          the Platform that is contrary to the Contract or otherwise illicit, and in particular that is outrageous,
          insulting, defamatory, abusive, violent, obscene or pornographic, or that includes incitement to
          discrimination or hatred based on race, religion, sex, or other, an incitement to crimes and misdemeanors, or
          an apology for crime, or likely to infringe the intellectual property rights or personality rights of third
          parties, or likely to alter the operation of the Services and / or the Platform in any way whatsoever.
        </p>
        <p className="py-2">
          To do this, Users may contact Njanoo, either by email at the address:{' '}
          <a href="&#x6d;&#97;&#105;&#x6c;&#x74;&#x6f;&#x3a;&#99;&#x6f;&#110;&#116;&#x61;&#x63;&#116;&#64;&#x6e;&#106;&#97;&#110;&#111;&#x2e;&#x6f;&#x72;&#x67;">
            &#99;&#x6f;&#110;&#116;&#x61;&#x63;&#116;&#64;&#x6e;&#106;&#97;&#110;&#111;&#x2e;&#x6f;&#x72;&#x67;
          </a>{' '}
          and proceed as follows:
        </p>
        <ul className="list-inside list-disc space-y-1 my-2">
          <li>Declare their identity;</li>
          <li>
            Describe the disputed content in a precise and detailed manner as well as its location on the Platform;
          </li>
          <li>
            Describe the factual and legal grounds for which the disputed content must be considered as manifestly
            unlawful and, consequently, removed from the Platform;
          </li>
          <li>
            Send a copy of the correspondence previously sent to the author of the contentious content to request its
            modification or removal and/or the justification that it has not been possible to contact the author.
          </li>
        </ul>
        <p className="py-2">
          Njanoo reserves the right to delete any illicit content or content that does not comply with the Contract that
          has been previously reported. Njanoo may sanction any clearly abusive report.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 7. Protection of personal data
        </h2>
        <p className="py-2">
          The Privacy Policy is accessible at the following link:{' '}
          <Link to="/terms/privacy-policy" className="text-blue-600">
            https://njano.org/terms/privacy-policy
          </Link>
        </p>
        <p className="py-2">
          Njanoo expressly invites the User to consult the Privacy Policy, which forms an integral part of these ToU.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 8. Obligations of Users and Charter of Good Conduct
        </h2>
        <h4>8.1. Obligations of Users</h4>
        <p className="py-2">Within the framework of the use of the Platform, the User undertakes to :</p>
        <ul className="list-inside list-disc space-y-1 my-2">
          <li>
            Guarantee the accuracy, integrity and legality of the information they provide on the Platform regarding
            their identity and contact details;
          </li>
          <li>Guarantee the proper use of the Platform;</li>
          <li>Create only one account on the Platform;</li>
          <li>
            Refrain from entering information and/or messages, comments and other content that are malicious,
            disparaging, defamatory, insulting, obscene, pornographic, violent, racist, xenophobic, discriminatory,
            deliberately misleading, illegal and/or contrary to public order or morality;
          </li>
          <li>
            To respect the rights of third parties, and in particular the right of each person to respect for his or her
            private life, image and other personality rights, as well as intellectual property rights (copyright,
            neighboring rights, database rights, trademark rights, patent rights, designs or models, trade secrets,
            etc.);
          </li>
          <li>
            Not to usurp the quality, attribute or identifier of another User or third party in such a way as to mislead
            or create any confusion as to their identity or the origin of the information or content (documents,
            messages, comments, etc.) that they publish or transmit on the Platform;
          </li>
          <li>Not to alter or disrupt the integrity of the Platform and/or the data contained therein;</li>
          <li>
            Not to attempt to gain unauthorized access to the Platform or its associated systems or networks or to
            intercept data;
          </li>
          <li>Use the Platform in compliance with applicable national and/or international laws and regulations.</li>
        </ul>
        <p className="py-2">
          In the event of a breach of any of these obligations, Njanoo reserves the right to temporarily or permanently
          suspend the User&#39;s account.
        </p>
        <h4>8.2. Charter of Good Conduct</h4>
        <p className="py-2">
          Njano provides a community website based on mutual aid and sharing within which discussions are open to all
          Members. Everyone is invited to take part in it by respecting the elementary rules of courtesy and the
          legislation in force.
        </p>
        <p className="py-2">
          The exchanges on Njano are moderated a posteriori, i.e. no message will be reread before its publication.
          Messages are therefore published immediately, but are likely to be checked by the moderation team and the
          administrators of the Platform, after publication by the Member.
        </p>
        <p className="py-2">
          The role of the moderation team and the administrators is to ensure the proper functioning of the Platform, by
          removing any message which, by their undignified, offensive, destructive or obviously off-topic nature, are
          detrimental to the exchanges. The moderators and administrators also exclude all messages that are contrary to
          the laws in force.
        </p>
        <p className="py-2">The following are thus subject to moderation (non-exhaustive list):</p>
        <ul className="list-inside list-disc space-y-1 my-2">
          <li>messages of a racist, hateful, homophobic, sexist or defamatory nature</li>
          <li>messages of an advertising nature</li>
          <li>obscene, pornographic or harassing messages</li>
          <li>
            messages mentioning precise coordinates such as a telephone number, a postal address and whose origin and
            accuracy are unverifiable by the moderators or could cause prejudice to people
          </li>
          <li>messages published in several copies</li>
          <li>messages that are off-topic or incite controversy</li>
          <li>messages in abbreviated language or whose spelling is too approximate</li>
          <li>messages written entirely in capital letters</li>
        </ul>
        <p className="py-2">
          In the event of non-compliance with these rules and instructions, the Njano team will apply certain sanctions
          to the Users concerned. The highest penalty is banning and consists of completely blocking access to the
          Platform for a User.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 9. Responsibility of Njanoo
        </h2>
        <h4>9.1. With regard to information and/or content published on the Platform by Users</h4>
        <p className="py-2">
          The information and content (documents, messages, comments) published by Users on the Platform are not checked
          upstream by Njanoo before being published on the Platform. On the other hand, they may be checked afterwards
          by Njanoo.
        </p>
        <h4>9.2 General liability</h4>
        <p className="py-2">
          Njanoo shall only be liable for direct damage suffered by Users, which is proven to be the result of the
          non-performance of one or more of its obligations.
        </p>
        <p className="py-2">On the other hand, Njanoo may not under any circumstances be held liable for:</p>
        <ul className="list-inside list-disc space-y-1 my-2">
          <li>
            Damage resulting from the User&#39;s actions, difficulties inherent in the operation of the Internet network
            and, more generally, telecommunication networks, regardless of their nature, from the actions of a third
            party or from a case of force majeure;
          </li>
          <li>
            Indirect damage resulting from the use of the Platform, which is defined in a non-limitative way as
            operating losses (turnover, income or profits), loss of opportunities, damage to image or reputation,
            commercial or economic loss;
          </li>
          <li>Any loss of data suffered by the User, even if caused by Njanoo.</li>
        </ul>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 10: Interruption of service
        </h2>
        <p className="py-2">
          Njanoo shall do everything in its power to ensure the proper functioning of the Platform and its
          accessibility, but is only bound by an obligation of means concerning the continuity of access to the
          Services. Njanoo cannot guarantee the continuity or performance of the Platform.
        </p>
        <p className="py-2">
          In the event of an update of the Platform for a technical change, it will be temporarily inaccessible.
        </p>
        <p className="py-2">
          Access to the Services may be interrupted for reasons of maintenance, updating and in case of emergency.
        </p>
        <p className="py-2">
          More generally, Njanoo shall not be held liable for any interruption of the Services, whatever the cause,
          duration or frequency, and shall not entitle the User to any compensation.
        </p>
        <p className="py-2">
          Consequently, Njanoo cannot be held responsible for the loss of money or reputation, nor for any special,
          indirect or consequential damage resulting from the interruption of the Services. Similarly, Njanoo cannot be
          held responsible for any damage to hardware, software or data (e.g. viral contamination) suffered by the User
          as a result of his/her use of the Services.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 11. Force majeure
        </h2>
        <p className="py-2">
          Neither party shall be liable to the other party for any delay in performance or non-performance due to the
          occurrence of an event beyond the control of the parties which could not reasonably be foreseen at the time of
          acceptance of the TOU and the effects of which could not be avoided by appropriate measures.
        </p>
        <p className="py-2">
          The event of force majeure suspends the obligations of the party concerned for the time the force majeure is
          in effect if this event is temporary. Nevertheless, the parties shall endeavour to minimise the consequences
          as far as possible. Failing this, if the impediment is definitive, the parties shall be released from their
          obligations under the conditions provided for in Art 283 of Code of Obligations and Contracts.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 12. Assignment
        </h2>
        <p className="py-2">
          Njanoo may assign the Contract and any of its rights under the Contract, in whole or in part, and may delegate
          any of its obligations under the Contract. The User may not assign the Contract, in whole or in part, to any
          third party.
        </p>
        <h2 className="font-heading md:text-2xl pt-6 pb-2 text-xl font-bold text-gray-900 break-normal">
          Article 13. Dispute Resolution
        </h2>
        <p className="py-2">
          The ToU are subject to Tunisian law. Any difficulties relating to the validity, application or interpretation
          of the ToU shall be submitted, in the absence of amicable agreement, to the courts of Tunisia, to which the
          Parties attribute territorial jurisdiction, even in the event of an incidental claim, a warranty claim or
          multiple defendants.
        </p>
        <p className="mt-4 italic text-sm">Publication date V.1 : july, 2024</p>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
